var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-breadcrumbs',{staticClass:"pa-0 pb-2",attrs:{"items":_vm.breadcrumbs,"divider":">"}}),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-12",attrs:{"cols":"auto"}},[_c('h1',[_vm._v("Customers")])]),_c('v-col',{staticClass:"pl-12"},[_c('v-text-field',{attrs:{"label":"Search Customers","outlined":"","dense":"","hide-details":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',{attrs:{"align":"center","justify":"end","no-gutters":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"depressed":"","color":"accent"},on:{"click":function($event){return _vm.$refs.customerForm.openForm()}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Add New Customer")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"depressed":"","to":{ name: 'module-drum-customers-archived' }}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-archive")])],1)]}}])},[_c('span',[_vm._v("View Archived")])])],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.customers,"no-data-text":"No Customers found"},scopedSlots:_vm._u([{key:"item.full_name",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
            name: 'module-drum-customers-profile',
            params: { customerId: item.id },
          }}},[_vm._v(_vm._s(item.full_name))])]}},{key:"item.phone",fn:function({ item }){return [(item.phone)?_c('div',[_vm._v(_vm._s(item.phone))]):_c('div',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.address",fn:function({ item }){return [(item.address1_1)?_c('div',{staticClass:"pt-2 pb-2"},[_c('div',[_vm._v(_vm._s(item.address1_1))]),_c('div',[_vm._v(_vm._s(item.address1_city))]),_c('div',[_vm._v(" "+_vm._s(item.address1_county)+", "+_vm._s(item.address1_postcode)+" ")]),_c('div',[_vm._v(_vm._s(item.address1_country))])]):_c('div',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"x-small":"","depressed":"","color":"green lighten-4 green--text","to":{
                name: 'module-drum-customers-profile',
                params: { customerId: item.id },
              }}},on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("View")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"x-small":"","depressed":"","color":"red lighten-4 red--text"},on:{"click":function($event){return _vm.openDelete(item)}}},on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-archive")])],1)]}}],null,true)},[_c('span',[_vm._v("Archive")])])]}}])})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.deleteDialog.open),callback:function ($$v) {_vm.$set(_vm.deleteDialog, "open", $$v)},expression:"deleteDialog.open"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Delete Customer")]),_c('v-card-text',[_vm._v("Are you sure you want to delete "+_vm._s(_vm.deleteDialog.customer.full_name)+"?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","depressed":""},on:{"click":_vm.resetDelete}},[_vm._v("No, cancel")]),_c('v-btn',{attrs:{"color":"success","depressed":"","loading":_vm.deleteDialog.loading},on:{"click":_vm.saveDelete}},[_vm._v(" Yes ")])],1)],1)],1),_c('CustomerForm',{ref:"customerForm"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }