<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Customers</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Customers"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  @click="$refs.customerForm.openForm()"
                  v-on="on"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Customer</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  class="mr-2"
                  v-on="on"
                  :to="{ name: 'module-drum-customers-archived' }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="customers"
        no-data-text="No Customers found"
      >
        <template v-slot:item.full_name="{ item }">
          <router-link
            :to="{
              name: 'module-drum-customers-profile',
              params: { customerId: item.id },
            }"
            >{{ item.full_name }}</router-link
          >
        </template>
        <template v-slot:item.phone="{ item }">
          <div v-if="item.phone">{{ item.phone }}</div>
          <div class="text--disabled" v-else>N/A</div>
        </template>
        <template v-slot:item.address="{ item }">
          <div class="pt-2 pb-2" v-if="item.address1_1">
            <div>{{ item.address1_1 }}</div>
            <div>{{ item.address1_city }}</div>
            <div>
              {{ item.address1_county }},
              {{ item.address1_postcode }}
            </div>
            <div>{{ item.address1_country }}</div>
          </div>
          <div class="text--disabled" v-else>N/A</div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="green lighten-4 green--text"
                v-on="on"
                class="mr-2"
                :to="{
                  name: 'module-drum-customers-profile',
                  params: { customerId: item.id },
                }"
              >
                <v-icon x-small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="deleteDialog.open" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Delete Customer</v-card-title>
        <v-card-text
          >Are you sure you want to delete
          {{ deleteDialog.customer.full_name }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteDialog.loading"
            @click="saveDelete"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CustomerForm ref="customerForm" />
  </div>
</template>

<script>
import CustomerForm from "../components/CustomerForm";

export default {
  components: {
    CustomerForm,
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Customers",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Name", value: "full_name" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Address", value: "address" },
        { text: "Actions", value: "actions", align: "right" },
      ],
      deleteDialog: {
        open: false,
        loading: false,
        customer: {},
      },
    };
  },

  computed: {
    customers() {
      let customers = this.$store.state.drum.customers["customers"];

      if (this.searchTerm !== "") {
        customers = customers.filter((c) => {
          const name = c.full_name.toLowerCase();
          const email = c.email ? c.email.toLowerCase() : "";
          return (
            name.includes(this.searchTerm.toLowerCase()) ||
            email.includes(this.searchTerm.toLowerCase())
          );
        });
      }

      return customers;
    },
  },

  methods: {
    openDelete(customer) {
      this.deleteDialog.customer = customer;
      this.deleteDialog.open = true;
    },

    resetDelete() {
      this.deleteDialog.loading = false;
      this.deleteDialog.open = false;
      this.deleteDialog.customer = {};
    },

    saveDelete() {
      const appId = this.$route.params.id;
      this.deleteDialog.loading = true;

      this.$store
        .dispatch("drum/customers/deleteCustomer", {
          appId,
          customerId: this.deleteDialog.customer.id,
        })
        .then(() => this.resetDelete())
        .catch(() => (this.loading = false));
    },
  },
};
</script>