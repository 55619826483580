<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Customer</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <v-form @submit.prevent="saveForm" method="post" id="customer-form">
          <v-row>
            <v-col cols="6" class="pb-0">
              <v-text-field
                label="First Name"
                v-model="fields.first_name"
                outlined
                :error="errors.hasOwnProperty('first_name')"
                :error-messages="errors['first_name']"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pb-0">
              <v-text-field
                label="Last Name"
                v-model="fields.last_name"
                outlined
                :error="errors.hasOwnProperty('last_name')"
                :error-messages="errors['last_name']"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pb-0">
              <v-text-field
                label="Email"
                v-model="fields.email"
                type="email"
                outlined
                :error="errors.hasOwnProperty('email')"
                :error-messages="errors['email']"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pb-0">
              <v-text-field
                label="Phone"
                v-model="fields.phone"
                type="phone"
                outlined
                :error="errors.hasOwnProperty('phone')"
                :error-messages="errors['phone']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-text-field
            label="Address Line 1"
            v-model="fields.address1_1"
            outlined
            :error="errors.hasOwnProperty('address1_1')"
            :error-messages="errors['address1_1']"
          ></v-text-field>
          <v-text-field
            label="Address Line 2"
            v-model="fields.address1_2"
            outlined
            :error="errors.hasOwnProperty('address1_2')"
            :error-messages="errors['address1_2']"
          ></v-text-field>
          <v-text-field
            label="Address Line 3"
            v-model="fields.address1_3"
            outlined
            :error="errors.hasOwnProperty('address1_3')"
            :error-messages="errors['address1_3']"
          ></v-text-field>
          <v-text-field
            label="City"
            v-model="fields.address1_city"
            outlined
            :error="errors.hasOwnProperty('address1_city')"
            :error-messages="errors['address1_city']"
          ></v-text-field>
          <v-text-field
            label="County"
            v-model="fields.address1_county"
            outlined
            :error="errors.hasOwnProperty('address1_county')"
            :error-messages="errors['address1_county']"
          ></v-text-field>
          <v-text-field
            label="Postcode"
            v-model="fields.address1_postcode"
            outlined
            :error="errors.hasOwnProperty('address1_postcode')"
            :error-messages="errors['address1_postcode']"
          ></v-text-field>
          <v-text-field
            label="Country"
            v-model="fields.address1_country"
            outlined
            :error="errors.hasOwnProperty('address1_country')"
            :error-messages="errors['address1_country']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="customer-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      customer: {},
      fields: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        address1_1: null,
        address1_2: null,
        address1_3: null,
        address1_city: null,
        address1_county: null,
        address1_postcode: null,
        address1_country: null,
      },
      errors: {},
    };
  },

  methods: {
    openForm: function (customer = null) {
      if (customer !== null) {
        this.isEditing = true;
        this.customer = customer;
      }

      this.dialog = true;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.customer = {};
      this.fields = {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        address1_1: null,
        address1_2: null,
        address1_3: null,
        address1_city: null,
        address1_county: null,
        address1_postcode: null,
        address1_country: null,
      };
      this.errors = {};
    },

    saveForm: function () {
      const appId = this.$route.params.id;

      this.loading = true;

      this.$store
        .dispatch("drum/customers/saveCustomer", {
          appId,
          isEditing: this.isEditing,
          fields: this.fields,
        })
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>